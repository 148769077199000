import React from "react";
import { FaLinkedin, FaFacebook, FaGoogle } from "react-icons/fa";

const items = [
  { name: "Google", icon: FaGoogle, link: "https://g.page/r/CQgogqjvKevdEAI/review" },
  { name: "LinkedIn", icon: FaLinkedin, link: "https://www.linkedin.com/company/sanero/" },
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/sanero" },
];

const Footer = () => {
  return (
    <footer className="w-full bg-[#3C4248] text-zinc-50">
      <div className="container mx-auto justify-between py-8 px-2">
        <div className="max-w-[1920px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-pink-500 py-8">
          {/* Add Footer content */}
        </div>
        <div className="flex flex-col max-w-[1280px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-zinc-50">
          <p className="py-4 md:text-lg lg:text-xl">2024 Sanero All Rights Reserved</p>
          <div className="flex justify-center sm:justify-between sm:w-[126px] pt-4 text-2xl">
            {items.map((item, index) => (
              <a
                key={item.name}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={item.name}
                className={`hover:text-zinc-300 text-3xl ${index !== items.length - 1 ? 'mr-2' : ''}`}
              >
                <item.icon />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
