import React from 'react';
import '../../index.css';
import Contacts from '../Contacts'; // Import the Contacts component

function About() {
  return (
    <div className="bg-zinc-100 font-medium">
      <section className="container mx-auto max-w-[1280px] text-zinc-800 bg-zinc-100 px-4 md:px-6 lg:px-8 pb-8">
        <div className="px-5 py-8 mx-auto">
          <h2 className="pb-8 text-2xl md:text-3xl lg:text-4xl font-bold font-questrial uppercase text-zinc-900 mt-48 text-center">Why Choose Us?</h2>
          <div className="flex flex-col space-y-6 text-left text-base md:text-lg lg:text-xl leading-relaxed">
            <p>Welcome to <span className="font-bold">SANERO</span>, your trusted partner for dental equipment repair and maintenance services in Norfolk and surrounding areas. Founded by Sandro Norim, SANERO is committed to delivering reliable, expert solutions tailored to the needs of dental practices across the East of England.</p>

            <p>With a Master’s degree in Biomedical Engineering specialising in Medical Instrumentation and over 10 years of field service engineering experience, Sandro brings a wealth of knowledge to every service call. His expertise is further enhanced by specialised training in Dental Equipment Servicing through Avensys UK, as well as advanced training in the principles of electrical safety testing procedures for biomedical equipment, in compliance with IEC BS 60601 and IEC BS 62353 standards. These qualifications ensure SANERO meets the highest standards in dental equipment care.</p>

            <p>At <span className="font-bold">SANERO</span>, we understand the importance of keeping your practice running smoothly. We offer maintenance and repair services for a wide range of dental equipment, including:</p>
            <ul className="list-disc list-inside">
              <li>Dental Compressors</li>
              <li>Suction Systems</li>
              <li>Autoclaves</li>
              <li>Dental Chairs</li>
              <li>Dental X-Ray</li>
              <li>Dental Handpieces</li>
            </ul>

            <p>Whether you need an annual service or urgent repairs, SANERO is here to support your clinic with personalised, dependable service. We look forward to partnering with you to keep your equipment in optimal condition.</p>
          </div>
        </div>
      </section>
      <Contacts />
    </div>
  );
}

export default About;