import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-zinc-100 text-zinc-800">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="mb-4">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="p-4 cursor-pointer duration-300 border border-[#FF4D9A] hover:border-[#FF4D9A] rounded-xl">
        Go to Home
      </Link>
    </div>
  );
}

export default NotFound;