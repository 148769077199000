import React, { useState, useEffect, useRef } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../index.css';

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Determine the background class based on the current path
  const backgroundClass = location.pathname === '/' ? 'bg-transparent' : 'bg-[#3C4248]';

  return (
    <nav className={`w-full ${backgroundClass} absolute top-0 left-0 z-50`}>
      <div className='container bg-transparent flex justify-between items-center max-w-[1920px] mx-auto px-6 py-6 text-zinc-50'>
        {/* Logo */}
        <RouterLink to="/" aria-label="Home">
          <img src="./logos/logo_nobg.svg" alt="Sanero Logo" className="object-cover max-h-12 md:max-h-14 lg:max-h-16"/>
        </RouterLink>

        {/* Burger Menu Icon */}
        <div className="md:hidden">
          <button onClick={toggleMenu} aria-label="Toggle menu">
            {isOpen ? <FaTimes className="text-3xl" /> : <FaBars className="text-3xl" />}
          </button>
        </div>

        {/* Menu Items */}
        <div ref={menuRef} className={`fixed top-0 left-0 h-full w-64 bg-transparent text-zinc-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:translate-x-0 md:flex md:flex-row md:space-x-4`}>
          <div className="flex flex-col p-6 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            {location.pathname === '/about' ? (
              <RouterLink 
                to="/" 
                className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] bg-[#3C4248] md:bg-transparent hover:border-[#FF4D9A] rounded-xl'
                aria-label="Home"
                onClick={toggleMenu}
              >
                Home
              </RouterLink>
            ) : (
              <RouterLink 
                to="/about" 
                className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] bg-[#3C4248] md:bg-transparent hover:border-[#FF4D9A] rounded-xl'
                aria-label="About"
                onClick={toggleMenu}
              >
                About
              </RouterLink>
            )}
            <ScrollLink 
              to="contacts" 
              smooth={true} 
              duration={500} 
              className='p-4 cursor-pointer duration-300 border border-[#FF4D9A] bg-[#3C4248] md:bg-transparent hover:border-[#FF4D9A] rounded-xl'
              aria-label="Contacts"
              onClick={toggleMenu}
            >
              Contacts
            </ScrollLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;