import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Define the handleWebVitals function
const handleWebVitals = (metric) => {
  console.log(metric);
  // You can also send the metrics to an analytics endpoint
  // fetch('/analytics', {
  //   method: 'POST',
  //   body: JSON.stringify(metric),
  //   headers: { 'Content-Type': 'application/json' },
  // });
};

// Create a root and render the App component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Call reportWebVitals and pass the metrics handling function
reportWebVitals(handleWebVitals);
