import React from 'react';
import '../index.css';

function Services() {
  return (
    <div className="bg-zinc-100">
      <section className="container mx-auto max-w-[1280px] text-zinc-800 bg-zinc-100 px-4 md:px-6 lg:px-8 pb-8">
        <div className="px-5 py-8 mx-auto">
          <h2 className="pb-4 text-2xl md:text-3xl lg:text-4xl font-bold text-zinc-900 text-center font-questrial uppercase">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 font-medium md:text-lg lg:text-xl text-center">
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/chair-icon.png" alt="Dental Chair" className="w-24 h-24 mb-4 mx-auto" /><br/>
              <h2><b className='uppercase'>Chair and Delivery System</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Inspection and maintenance of hydraulic and mechanical components.</li>
                <li>Troubleshooting of the delivery unit, including handpiece tubing, and spittoon.</li>
                <li>Testing and calibration of chair controls and foot pedals for optimal performance.</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/compres-icon.png" alt="Compressor" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <h2><b className='uppercase'>Dental Compressor</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Detailed cleaning and filter replacement to ensure air quality.</li>
                <li>Pressure system checks and calibration to comply with safety regulations.</li>
                <li>Wear checks on motors and moving parts to prevent breakdowns.</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/vacum-icon.png" alt="Suction Pump" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <h2><b className='uppercase'>Suction System</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Cleaning and descaling to maintain suction efficiency.</li>
                <li>Inspection and replacement of filters, seals, and tubing as needed.</li>
                <li>Motor performance checks to identify and resolve potential issues early.</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/steril-icon.png" alt="Autoclave" className="w-24 h-24 mb-4 mx-auto" /><br/>
              <h2><b className='uppercase'>Autoclave</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Testing and calibration of sterilisation cycles for accurate operation.</li>
                <li>Inspection and replacement of seals, gaskets, and filters.</li>
                <li>Cleaning and descaling to prevent build-up and ensure compliance with hygiene standards.</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/xray-icon.png" alt="X-Ray" className="w-24 h-24 mb-4 mx-auto" /><br/>
              <h2><b className='uppercase'>Dental X-Ray</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Troubleshooting and testing of mainboards to ensure proper functionality.</li>
                <li>Diagnosis and resolution of power-related issues to prevent downtime.</li>
                <li>Checking for and addressing oil leaks to maintain performance and safety.</li>
              </ul>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <img src="/icons/hand-icon.png" alt="Handpiece" className="w-24 h-24 mb-4 mx-auto" /><br/> 
              <h2><b className='uppercase'>Dental Handpiece</b><br/><br/></h2>
              <ul className="list-none list-inside text-left space-y-2">
                <li>Cleaning, lubrication, and overhaul of turbine and rotary components.</li>
                <li>Replacement of worn bearings, o-rings, and other critical parts.</li>
                <li>Performance testing to ensure smooth operation and extended lifespan.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;